import { PrismicRichText } from '@prismicio/react';
import { Layout, SEO } from 'components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import moment from 'moment';
import * as blogListPageClasses from './index.module.scss';

interface IBlogListPageProps {
  data: any;
}

interface ILatestBlogBannerProps {
  latestBlogData: any;
  publishedAt: string;
  uid: string;
}

interface IBlogListingSectionProps {
  blogs: any[];
}


const BlogListingSection: React.FC<IBlogListingSectionProps> = ({ blogs }) => {

  return (
    <section className='section'>
      <div className="container">
        <div className='row'>
          {
            blogs.map(
              (blog: any) => (
                <div key={blog.uid} className='col-12 col-lg-4 mb-20'>
                  <Link to={`/blogs/${blog.uid}`} className="text-decoration-none">

                    <GatsbyImage
                      image={blog.data.body[0].primary.feature_image.gatsbyImageData}
                      alt={blog.data.body[0].primary.feature_image.alt}
                      objectFit='contain' />

                    <PrismicRichText
                      field={blog.data.body[0].primary.blog_title.richText}
                      components={{
                        heading1: (props: any) => <h2 className='Lato_28_Semibold Font_Color_Primary mb-6 mt-15'>{props.children}</h2>,
                      }} />
                    <p className="Lato_14_Regular Font_Color_Quaternary mb-6">
                      {moment(blog.data.published_at, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
                    </p>
                    <p className="Lato_18_Regular Font_Color_Primary mb-6">
                      {blog.data.body[0].primary.blog_content.text.substring(0, 200)}...
                    </p>

                  </Link>
                </div>
              )
            )
          }
        </div>
      </div>
    </section>
  );
};


const LatestBlogBanner: React.FC<ILatestBlogBannerProps> = ({ latestBlogData, publishedAt, uid }) => {
  return (

    <Link to={`/blogs/${uid}`} className="text-decoration-none more-btn">
      <section className='section'>
        <div className="container">
          <div className={`row align-items-center ${blogListPageClasses.LatestBlog_Row}`}>
            <div className="col-12 col-md-7 h-100">
              <GatsbyImage
                className='h-100'
                image={latestBlogData.feature_image.gatsbyImageData}
                alt={latestBlogData.feature_image.alt}
                objectFit='contain' />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <div className="caption">
                <p className='Lato_16_Regular Font_Color_Error mb-8'> {latestBlogData.blog_category} </p>
                <PrismicRichText
                  field={latestBlogData.blog_title.richText}
                  components={{
                    heading1: (props: any) => <h2 className='Lato_28_Semibold Font_Color_Primary mb-6'>{props.children}</h2>,
                  }} />
                <p className="Lato_14_Regular Font_Color_Quaternary mb-6">
                  {moment(publishedAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
                </p>
                <p className="Lato_18_Regular Font_Color_Primary mb-6">
                  {latestBlogData.blog_content.text.substring(0, 200)}...
                </p>
                <p className="Lato_16_Regular Font_Color_Primary text-decoration-underline">
                  ----- Read More
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};

const BlogListPage: React.FunctionComponent<IBlogListPageProps> = ({ data }) => {

  if (!data) {
    return null;
  }

  const blogs = data.allPrismicBlogPage.nodes;

  return (
    <Layout>
      <SEO
        title="Teamble Blog"
        description='Latest news, content, and science-backed discussions on workplace culture. Teamble is advised by the global thought leader in organizational psychology.' />
      <div style={{ maxWidth: '1170px' }} className='m-auto'>
        <LatestBlogBanner
          uid={blogs[0].uid}
          publishedAt={blogs[0].data.published_at}
          latestBlogData={blogs[0].data.body[0].primary} />
        <BlogListingSection blogs={blogs} />
      </div>
    </Layout>
  );

};


export const query = graphql`
  {
    allPrismicBlogPage(sort: {fields: data___published_at, order: DESC}) {
      nodes {
        uid
        data {
          body {
            ... on PrismicBlogPageDataBodyBlog {
              id
              primary {
                feature_image {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
                blog_title {
                  richText
                }
                blog_content {
                  text
                  richText
                }
                blog_category
                author_name
                author_description
              }
              slice_type
            }
          }
          published_at
        }
      }
    }
  }
`;

export default BlogListPage;
